import { Component } from "react";
export const getAvinorColor = (color) => getComputedStyle(document.documentElement).getPropertyValue(color).toString().trim();
export class Transform extends Component {
}
Transform.rotate = (degree = 0) => {
    return `rotate(${degree})`;
};
Transform.translate = (x, y) => {
    return `translate(${x}, ${y})`;
};
export const withSandbox = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("debug") === "sandbox";
};
export const isInUnion = (value, unionValues) => {
    return unionValues.includes(value);
};
