import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import classNames from "classnames";
import { t } from "i18next";
import PageHeader from "@shared/components/PageHeader/PageHeader";
const styleSheet = {
    root: classNames("page"),
    header: classNames("page__header"),
    body: classNames("page__body"),
};
const NotFoundPage = () => {
    return (_jsxs("div", { className: styleSheet.root, children: [_jsx("div", { className: styleSheet.header, children: _jsx(PageHeader, { title: t("error.PageNotFound.title"), description: t("error.PageNotFound.description"), active: 0 }) }), _jsx("div", { className: styleSheet.body, children: t("error.PageNotFound.feedback") })] }));
};
export default NotFoundPage;
