import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import FrontPage from "@pages/FrontPage";
import NotFoundPage from "@pages/NotFoundPage";
import "./App.scss";
import Footer from "./shared/components/Footer/Footer";
import Header from "./shared/components/Header/Header";
const App = ({ pca }) => {
    return (_jsx(MsalProvider, { instance: pca, children: _jsxs("div", { className: "app", children: [_jsx("div", { className: "app__header", children: _jsx(Header, {}) }), _jsx("div", { className: "app__body", children: _jsx("main", { className: "app__body__main", children: _jsx(Pages, {}) }) }), _jsx("div", { className: "app__footer", children: _jsx(Footer, {}) })] }) }));
};
const Pages = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(FrontPage, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFoundPage, {}) })] }));
};
export default App;
