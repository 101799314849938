export var Granularity;
(function (Granularity) {
    Granularity["Day"] = "day";
    Granularity["Hour"] = "hour";
    Granularity["ThirtyMinutes"] = "30min";
    Granularity["FifteenMin"] = "15min";
    Granularity["FiveMin"] = "5min";
})(Granularity || (Granularity = {}));
export var Delay;
(function (Delay) {
    Delay[Delay["TenSeconds"] = 10000] = "TenSeconds";
    Delay[Delay["FifteenSeconds"] = 15000] = "FifteenSeconds";
    Delay[Delay["ThirtySeconds"] = 30000] = "ThirtySeconds";
    Delay[Delay["OneMinute"] = 60000] = "OneMinute";
    Delay[Delay["FifteenMinutes"] = 900000] = "FifteenMinutes";
    Delay[Delay["ThirtyMinutes"] = 1800000] = "ThirtyMinutes";
    Delay[Delay["OneHour"] = 3600000] = "OneHour";
    Delay[Delay["TwentyFourHours"] = 86400000] = "TwentyFourHours";
})(Delay || (Delay = {}));
export var Page;
(function (Page) {
    Page["Baggage"] = "Baggage";
    Page["Dashboard"] = "Dashboard";
    Page["DeIce"] = "DeIce";
    Page["Delays"] = "Delays";
    Page["FacilityStatus"] = "FacilityStatus";
    Page["Flights"] = "Flights";
    Page["Pax"] = "Pax";
    Page["PunctualityRegularity"] = "PunctualityRegularity";
    Page["Sandbox"] = "Sandbox";
    Page["OperationalMessages"] = "OperationalMessages";
})(Page || (Page = {}));
export var TimeScope;
(function (TimeScope) {
    TimeScope["Today"] = "Today";
    TimeScope["Forecast"] = "Forecast";
    TimeScope["History"] = "History";
})(TimeScope || (TimeScope = {}));
export var FlightDirection;
(function (FlightDirection) {
    FlightDirection["All"] = "All";
    FlightDirection["Arrival"] = "Arrival";
    FlightDirection["Departure"] = "Departure";
})(FlightDirection || (FlightDirection = {}));
export var FormatPattern;
(function (FormatPattern) {
    FormatPattern["yyyy_MM_dd_HH_00"] = "yyyy-MM-dd'T'HH:00";
    FormatPattern["yyyy_MM_dd_HH_00_Z"] = "yyyy-MM-dd'T'HH:00'Z";
    FormatPattern["yyyy_MM_dd_HH_mm"] = "yyyy-MM-dd'T'HH:mm";
    FormatPattern["yyyy_MM_dd_HH_mm_Z"] = "yyyy-MM-dd'T'HH:mm'Z'";
    FormatPattern["yyyy_MM_dd"] = "yyyy-MM-dd";
    FormatPattern["yyyy_MM_dd_Z"] = "yyyy-MM-dd'Z";
    FormatPattern["d_LLL"] = "d LLL";
    FormatPattern["d_LLLL"] = "d LLLL";
    FormatPattern["d_MMMM"] = "d MMMM";
    FormatPattern["LL"] = "LL";
    FormatPattern["H"] = "H";
    FormatPattern["H_mm"] = "H:mm";
    FormatPattern["HH"] = "HH";
    FormatPattern["HH_00"] = "HH:00";
    FormatPattern["PPP"] = "PPP";
    FormatPattern["PPPP"] = "PPPP";
    FormatPattern["PP"] = "PP";
    FormatPattern["p"] = "p";
    FormatPattern["dd"] = "dd";
    FormatPattern["HH_mm"] = "HH:mm";
    FormatPattern["hh_mm_a"] = "hh:mm a";
    FormatPattern["kk_mm"] = "kk:mm";
})(FormatPattern || (FormatPattern = {}));
export var RouteEnum;
(function (RouteEnum) {
    RouteEnum["FrontPage"] = "/";
})(RouteEnum || (RouteEnum = {}));
