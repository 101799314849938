import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import { SiteHeader } from "@avinor/shared-react";
import { breakpoints } from "@shared/constants";
import { useMediaQuery } from "@shared/hooks/useMediaQuery";
import SignOutButton from "@auth/components/SignOutButton/SignOutButton";
import { MenuButton } from "../MenuButton/MenuButton";
const Header = () => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsxs("div", { className: "site-header-wrapper", children: [_jsxs("div", { className: "site-header-wrapper__top", children: [_jsx(SiteHeader, { href: "/" }), _jsx("span", { className: "site-header-wrapper__top__text", children: "B2B MVP App" })] }), isMobile ? _jsx(MenuButton, {}) : _jsx(SignOutButton, {})] }));
};
export default Header;
