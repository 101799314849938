import { jsx as _jsx } from "react/jsx-runtime";
import "./style.scss";
import classNames from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { BarsOutlined } from "@avinor/react-icons";
import { breakpoints } from "@shared/constants";
import { useMediaQuery } from "@hooks/useMediaQuery";
import { menuAtom } from "@recoil/atoms";
export const styleSheet = {
    root: (isMobile) => classNames("mobile-menu-icon", {
        "mobile-menu-icon--mobile": isMobile === true,
    }),
    closeIcon: classNames("mobile-menu-icon--mobile__close-icon"),
};
export const MenuButton = () => {
    const open = useRecoilValue(menuAtom);
    const setOpen = useSetRecoilState(menuAtom);
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsx("div", { onClick: () => setOpen(!open), className: styleSheet.root(isMobile), children: !open ? _jsx(BarsOutlined, { color: "white", width: "1.5rem", height: "1.5rem" }) : _jsx("div", { className: styleSheet.closeIcon }) }));
};
