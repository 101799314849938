import { BrowserCacheLocation } from "@azure/msal-browser";
import config from "../../config";
export const msalConfig = {
    auth: {
        clientId: config.AZURE_AD_B2C.CLIENT_ID,
        authority: config.AZURE_AD_B2C.AUTHORITY,
        redirectUri: config.AZURE_AD_B2C.REDIRECT_URL,
        postlogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
    },
};
export const loginRequest = {
    scopes: ["User.Read"],
};
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
