var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./style.scss";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import PageHeader from "@shared/components/PageHeader/PageHeader";
import { loginRequest } from "../auth/config";
import { ProfileData } from "../shared/components/Profile/ProfileData";
import { callMsGraph } from "../utils/MsGraphApiCall";
const styleSheet = {
    root: classNames("page"),
    header: classNames("page__header"),
    body: classNames("page__body"),
    code: classNames("page__code"),
};
const FrontPage = () => {
    const { instance, accounts, inProgress } = useMsal();
    const userAccount = accounts[0];
    const [graphData, setGraphData] = useState(null);
    const [imageUrl, setImageUrl] = useState();
    const [accessToken, setAccessToken] = useState();
    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph()
                .then(response => {
                setGraphData(response);
                setImageUrl(response.profilePhotoUrl);
                setAccessToken(response.accessToken);
            })
                .catch(e => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(Object.assign(Object.assign({}, loginRequest), { account: instance.getActiveAccount() }));
                }
            });
        }
    }, [inProgress, graphData, instance]);
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Call the login function to initiate the authentication process
            yield instance.loginRedirect();
        }
        catch (error) {
            // Handle any errors that occur during the login process
            console.error("Error during login:", error);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(AuthenticatedTemplate, { children: _jsxs("div", { className: styleSheet.root, children: [_jsx("div", { className: styleSheet.header, children: _jsx(PageHeader, { title: "Hello, World!", description: "Du er innlogget.", active: 1 }) }), _jsxs("div", { className: styleSheet.body, children: [_jsx("h2", { children: "User Account Information:" }), _jsxs("div", { children: [_jsx("div", { children: imageUrl && _jsx("img", { src: imageUrl }) }), _jsxs("div", { children: [_jsxs("p", { children: ["Display Name: ", userAccount === null || userAccount === void 0 ? void 0 : userAccount.name] }), _jsxs("p", { children: ["Username: ", userAccount === null || userAccount === void 0 ? void 0 : userAccount.username] }), _jsxs("p", { children: ["Tenant id: ", userAccount === null || userAccount === void 0 ? void 0 : userAccount.tenantId] }), _jsx("div", { children: Array.isArray(userAccount === null || userAccount === void 0 ? void 0 : userAccount.idTokenClaims) ? (userAccount === null || userAccount === void 0 ? void 0 : userAccount.idTokenClaims.map((claim, index) => (_jsx("div", { children: claim }, index)))) : (
                                                    // Handle the case when idTokenClaims is not an array
                                                    _jsx("div", { children: "Claims are not available" })) })] })] }), graphData ? _jsx(ProfileData, { graphData: graphData }) : null, _jsx("h2", { children: "Access Token" }), _jsx("div", { children: _jsx("p", { className: styleSheet.code, children: accessToken }) })] })] }) }), _jsx(UnauthenticatedTemplate, { children: _jsxs("div", { className: styleSheet.root, children: [_jsx("div", { className: styleSheet.header, children: _jsx(PageHeader, { title: "Hello, World!", description: "Du er ikke innlogget.", active: 1 }) }), _jsx("div", { className: styleSheet.body, children: _jsx("button", { onClick: handleLogin, children: "Log In" }) })] }) })] }));
};
export default FrontPage;
