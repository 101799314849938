import { getAvinorColor } from "@utils/Utils";
export const AvinorColor = {
    VioletPrimary: getAvinorColor("--avinor-violet-light-400"),
    VioletPrimaryLight: getAvinorColor("--avinor-violet-light-300"),
    VioletPrimaryDark: getAvinorColor("--avinor-violet-light-500"),
    MintGreenPrimary: getAvinorColor("--avinor-mint-green-300"),
    MintGreenPrimaryLight: getAvinorColor("--avinor-mint-green-200"),
    MintGreenPrimaryDark: getAvinorColor("--avinor-mint-green-00"),
    YellowWarningPrimary: getAvinorColor("--avinor-yellow-400"),
    YellowWarningPrimaryLight: getAvinorColor("--avinor-yellow-50"),
    YellowWarningPrimaryDark: getAvinorColor("--avinor-yellow-600"),
    RedAlarmPrimary: getAvinorColor("--avinor-red-400"),
    RedAlarmPrimaryLight: getAvinorColor("--avinor-red-50"),
    RedAlarmPrimaryDark: getAvinorColor("--avinor-red-600"),
    BlueAttentionPrimary: getAvinorColor("--avinor-blue-400"),
    BlueAttentionPrimaryLight: getAvinorColor("--avinor-blue-50"),
    BlueAttentionPrimaryDark: getAvinorColor("--avinor-blue-600"),
    GreenSuccessPrimary: getAvinorColor("--avinor-green-400"),
    GreenSuccessPrimaryLight: getAvinorColor("--avinor-green-50"),
    GreenSuccessPrimaryDark: getAvinorColor("--avinor-green-600"),
    ChartGrey900: getAvinorColor("--avinor-grey-900"),
    ChartGrey200: getAvinorColor("--avinor-grey-200"),
    ChartVioletLight100: getAvinorColor("--avinor-violet-light-100"),
    ChartVioletLight400: getAvinorColor("--avinor-violet-light-400"),
    ChartVioletLight600: getAvinorColor("--avinor-violet-light-600"),
    ChartVioletDark600: getAvinorColor("--avinor-violet-dark-600"),
    ChartMintGreen400: getAvinorColor("--avinor-mint-green-400"),
    ChartMintGreen300: getAvinorColor("--avinor-mint-green-300"),
    ChartTangerine200: getAvinorColor("--avinor-tangerine-200"),
    MintGreen50: getAvinorColor("--avinor-mint-green-50"),
    MintGreen100: getAvinorColor("--avinor-mint-green-100"),
    MintGreen200: getAvinorColor("--avinor-mint-green-200"),
    MintGreen300: getAvinorColor("--avinor-mint-green-300"),
    MintGreen400: getAvinorColor("--avinor-mint-green-400"),
    MintGreen500: getAvinorColor("--avinor-mint-green-500"),
    MintGreen600: getAvinorColor("--avinor-mint-green-600"),
    Green50: getAvinorColor("--avinor-green-50"),
    Green100: getAvinorColor("--avinor-green-100"),
    Green200: getAvinorColor("--avinor-green-200"),
    Green300: getAvinorColor("--avinor-green-300"),
    Green400: getAvinorColor("--avinor-green-400"),
    Green500: getAvinorColor("--avinor-green-500"),
    Green600: getAvinorColor("--avinor-green-600"),
    VioletDark50: getAvinorColor("--avinor-violet-dark-50"),
    VioletDark100: getAvinorColor("--avinor-violet-dark-100"),
    VioletDark200: getAvinorColor("--avinor-violet-dark-200"),
    VioletDark300: getAvinorColor("--avinor-violet-dark-300"),
    VioletDark400: getAvinorColor("--avinor-violet-dark-400"),
    VioletDark500: getAvinorColor("--avinor-violet-dark-500"),
    VioletDark600: getAvinorColor("--avinor-violet-dark-600"),
    VioletLight50: getAvinorColor("--avinor-violet-light-50"),
    VioletLight100: getAvinorColor("--avinor-violet-light-100"),
    VioletLight200: getAvinorColor("--avinor-violet-light-200"),
    VioletLight300: getAvinorColor("--avinor-violet-light-300"),
    VioletLight400: getAvinorColor("--avinor-violet-light-400"),
    VioletLight500: getAvinorColor("--avinor-violet-light-500"),
    VioletLight600: getAvinorColor("--avinor-violet-light-600"),
    Yellow50: getAvinorColor("--avinor-yellow-50"),
    Yellow100: getAvinorColor("--avinor-yellow-100"),
    Yellow200: getAvinorColor("--avinor-yellow-200"),
    Yellow300: getAvinorColor("--avinor-yellow-300"),
    Yellow400: getAvinorColor("--avinor-yellow-400"),
    Yellow500: getAvinorColor("--avinor-yellow-500"),
    Yellow600: getAvinorColor("--avinor-yellow-600"),
    Blue50: getAvinorColor("--avinor-blue-50"),
    Blue100: getAvinorColor("--avinor-blue-100"),
    Blue200: getAvinorColor("--avinor-blue-200"),
    Blue300: getAvinorColor("--avinor-blue-300"),
    Blue400: getAvinorColor("--avinor-blue-400"),
    Blue500: getAvinorColor("--avinor-blue-500"),
    Blue600: getAvinorColor("--avinor-blue-600"),
    Red50: getAvinorColor("--avinor-red-50"),
    Red100: getAvinorColor("--avinor-red-100"),
    Red200: getAvinorColor("--avinor-red-200"),
    Red300: getAvinorColor("--avinor-red-300"),
    Red400: getAvinorColor("--avinor-red-400"),
    Red500: getAvinorColor("--avinor-red-500"),
    Red600: getAvinorColor("--avinor-red-600"),
    Tangerine50: getAvinorColor("--avinor-tangerine-50"),
    Tangerine100: getAvinorColor("--avinor-tangerine-100"),
    Tangerine200: getAvinorColor("--avinor-tangerine-200"),
    Tangerine300: getAvinorColor("--avinor-tangerine-300"),
    Tangerine400: getAvinorColor("--avinor-tangerine-400"),
    Tangerine500: getAvinorColor("--avinor-tangerine-500"),
    Tangerine600: getAvinorColor("--avinor-tangerine-600"),
    Grey50: getAvinorColor("--avinor-grey-50"),
    Grey100: getAvinorColor("--avinor-grey-100"),
    Grey200: getAvinorColor("--avinor-grey-200"),
    Grey300: getAvinorColor("--avinor-grey-300"),
    Grey400: getAvinorColor("--avinor-grey-400"),
    Grey500: getAvinorColor("--avinor-grey-500"),
    Grey600: getAvinorColor("--avinor-grey-600"),
    Grey700: getAvinorColor("--avinor-grey-700"),
    Grey800: getAvinorColor("--avinor-grey-800"),
    Grey900: getAvinorColor("--avinor-grey-900"),
    White: getAvinorColor("--avinor-white"),
};
