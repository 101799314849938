var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import authProvider from "../auth/authProvider";
import { loginRequest, graphConfig } from "../auth/config";
export function callMsGraph(accessToken) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!accessToken) {
            const account = authProvider.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }
            const response = yield authProvider.acquireTokenSilent(Object.assign(Object.assign({}, loginRequest), { account: account }));
            accessToken = response.accessToken;
        }
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        const options = {
            method: "GET",
            headers: headers
        };
        const profileData = yield fetch(graphConfig.graphMeEndpoint, options)
            .then(response => response.json())
            .catch(error => console.log(error));
        const profilePhotoUrl = yield getProfilePhoto(accessToken);
        return Object.assign(Object.assign({}, profileData), { profilePhotoUrl, accessToken });
    });
}
export function getProfilePhoto(accessToken) {
    return __awaiter(this, void 0, void 0, function* () {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        const options = {
            method: "GET",
            headers: headers
        };
        return fetch(graphConfig.graphMeEndpoint + '/photos/48x48/$value', options)
            .then(response => response.blob())
            .then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            return objectURL;
        })
            .catch(error => console.log(error));
    });
}
/*// TODO
export async function getUser(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<User> {
    ensureClient(authProvider);
  
    // Return the /me API endpoint result as a User object
    const user: User = await graphClient!.api('/me')
      // Only retrieve the specific fields needed
      .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
  
    return user;
  }*/ 
