import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config";
const authProvider = new PublicClientApplication(msalConfig);
authProvider.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        const payload = event.payload;
        const account = payload.account;
        authProvider.setActiveAccount(account);
    }
});
export default authProvider;
