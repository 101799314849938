import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./style.scss";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BoxArrowOutRightOutlined } from "@avinor/react-icons";
import { AvinorColor, breakpoints } from "@shared/constants";
import { useMediaQuery } from "@shared/hooks/useMediaQuery";
export const styleSheet = {
    root: classNames("logout-button"),
    rootMobile: classNames("logout-button-mobile"),
    wrapper: classNames("logout-button-mobile__wrapper"),
    text: classNames("logout-button-mobile__wrapper__text"),
};
const SignOutButton = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const currentAccount = instance.getActiveAccount();
    const [name, setName] = useState("");
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const handleSignOut = () => {
        instance.logoutRedirect();
    };
    useEffect(() => {
        if (currentAccount && currentAccount.name) {
            setName(currentAccount.name);
        }
        if (currentAccount && !currentAccount.name) {
            setName(currentAccount.username);
        }
    }, [instance]);
    return (_jsx(_Fragment, { children: isAuthenticated && (_jsx("button", { onClick: handleSignOut, className: isMobile ? styleSheet.rootMobile : styleSheet.root, children: isMobile ? (_jsxs("div", { className: styleSheet.wrapper, children: [_jsx(BoxArrowOutRightOutlined, { width: "1.5rem", height: "1.5rem", color: AvinorColor.Grey50 }), _jsx("span", { className: styleSheet.text, children: " Logg ut" })] })) : (`Logg ut ${name}`) })) }));
};
export default SignOutButton;
