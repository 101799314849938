import i18next, { use } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import nb from "./nb.json";
const options = {
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    debug: true,
    lng: "nb",
    resources: {
        en: {
            common: en.en,
        },
        nb: {
            common: nb.nb,
        },
    },
    fallbackLng: "nb",
    ns: ["common"],
    defaultNS: "common",
    react: {
        wait: false,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
    },
};
use(initReactI18next).init(options);
export default i18next;
