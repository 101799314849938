import dev from "./default.json";
import production from "./production.json";
import test from "./test.json";
// Default to production
let env = "production";
try {
    if (process.env.ENVIRONMENT != undefined || process.env.ENVIRONMENT != null) {
        env = process.env.ENVIRONMENT;
    }
}
catch (e) {
    /* empty */
}
const config = {
    dev,
    test,
    production,
};
let envConfig = config[env];
const window = global.window;
try {
    if (window !== undefined && window.__CONFIG__ !== undefined) {
        const windowEnv = window.__CONFIG__.environment;
        if (windowEnv === "dev" || windowEnv === "test" || windowEnv === "production") {
            envConfig = config[windowEnv];
        }
    }
    else {
        envConfig = config[env.trim()];
    }
}
catch (e) {
    console.error(e);
}
const returnable = envConfig;
export default returnable;
